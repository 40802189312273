<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Lịch sử thay đổi hóa đơn'">
          <template v-slot:preview>
            <!-- Header session -->
            <div class="col-md-12 mb-5">
              <b-form>
                <b-row class="mb-5">
                  <b-col style="padding-left: 0px">
                    <b-input placeholder="Từ ngày" size="sm"></b-input>
                  </b-col>
                  <b-col>
                    <b-input placeholder="Đến ngày" size="sm"></b-input>
                  </b-col>
                  <b-col>
                    <b-input placeholder="Cửa hàng" size="sm"></b-input>
                  </b-col>
                  <b-col style="padding-right: 0px"></b-col>
                </b-row>

                <b-row>
                  <b-col style="padding-left: 0px">
                    <b-input placeholder="Người sửa" size="sm"></b-input>
                  </b-col>
                  <b-col>
                    <b-input placeholder="Loại log" size="sm"></b-input>
                  </b-col>
                  <b-col>
                    <b-input placeholder="ID phiếu" size="sm"></b-input>
                  </b-col>
                  <b-col style="padding-right: 0px">
                    <b-input placeholder="Khách hàng" size="sm"></b-input>
                  </b-col>
                </b-row>
              </b-form>
            </div>
            <!-- End of Header session -->
            <!-- Table session -->
            <b-table
              :items="histories"
              :fields="fields"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item @click="viewDetail(row.item)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="flaticon-eye icon-color"
                        ></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- End of Table session -->

            <!-- Paginate session -->
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số lịch sử thay đổi hóa đơn:
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="customPagination"
                  v-show="totalPages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="totalPages"
                  use-router
                  @change="fetchInstallments()"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
            <!-- End of Paginate session -->
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '../../../../utils/saveDataToLocal';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import moment from 'moment';

export default {
  data() {
    return {
      histories: [],
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important',
      },
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      page: 1,
      search: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'referenceId',
          label: 'ID hóa đơn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'type',
          label: 'Kiểu log',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'billType',
          label: 'Loại hóa đơn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'updatedBy',
          label: 'Người thao tác',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'createdAt',
          label: 'Thời gian tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        { key: 'actions', label: '' },
      ],
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Lịch sử', route: 'bill-history' },
      { title: 'Lịch sử thay đổi hóa đơn' },
    ]);
  },
  created() {
    this.fetchBillHistory();
  },
  methods: {
    getHistoryTypeName(typeId) {
      switch (typeId) {
        case 1:
          return 'đơn hàng';
        case 2:
          return 'hóa đơn';
        case 3:
          return 'sản phẩm';
        default:
          return '';
      }
    },
    getBillTypeByName(typeId) {
      switch (typeId) {
        case 1:
          return 'Hóa đơn bán lẻ';
        case 2:
          return 'Hóa đơn bán buôn';
        case 3:
          return 'Trả hàng';
        default:
          return '';
      }
    },
    viewDetail: function (item) {
      this.$router.push({
        name: 'detail-bill-history',
        query: { id: item.id },
      });
    },
    fetchBillHistory: async function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      if (!this.search) {
        this.search = '';
      }

      let params = {
        page: this.page,
        pageSize: 10,
        search: this.search,
        //  loại hóa đơn
        type: 2,
      };

      ApiService.setHeader();
      ApiService.query('/histories', { params }).then((response) => {
        this.installments = [];
        let {
          result,
          totalPages,
          currentPage,
          totalItems,
        } = response.data.data;

        this.totalPages = totalPages;
        this.currentPage = currentPage;
        this.totalItems = totalItems;

        result.map((item) => {
          let history = {
            id: item.id,
            type: 'Sửa ' + this.getHistoryTypeName(item.type),
            referenceId: item.referenceId,
            billType: this.getBillTypeByName(item.billType),
            action: item.action,
            description: item.description,
            updatedBy: item.updatedBy.fullName,
            createdAt: moment(item.createdAt).format('HH:mm - DD/MM/YYYY'),
          };
          this.histories.push(history);
        });
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('EMPLOYEE_VIEW')) {
        count++;
      }
      if (localData.checkPermission('EMPLOYEE_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.icon-color {
  color: #464e5f;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
